
import { defineComponent, ref, onMounted, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { BoxCategory } from "@/store/modules/BoxCategoryModule"
import { BoxInfo, BoxType, BoxItemInfo, BoxLevelItemInfo } from "@/store/modules/BoxInfoModule"
import { Level } from "@/store/modules/LevelModule"
import { Plus } from "@element-plus/icons-vue"
import NewBoxItemInfoModal from "@/components/modals/forms/NewBoxItemInfoModal.vue"
import * as _ from 'lodash'

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus,
    NewBoxItemInfoModal
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("套系新增", ["套系管理;box_infos"])
      } else {
        setCurrentPageBreadcrumbs("套系编辑", ["套系管理;box_infos", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const box_info = ref({} as BoxInfo)
    box_info.value.box_item_infos_attributes = []
    box_info.value.hidden_gifts_attributes = []
    box_info.value.box_level_item_infos_attributes = []

    const onCancel = () => {
      router.push({ name: "box_infos" });
    };

    const isNew = !route.params.id;

    var add_type = 'normal'
    var currentLevel = {} as any

    const isSubmit = ref(false)

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (isSubmit.value) {
        return
      }

      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          isSubmit.value = true

          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_BOX_INFO,
                box_info.value
              )
              .then(() => {
                router.push({ name: "box_infos" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
                isSubmit.value = false
              });
          } else {
            store
              .dispatch(Actions.UPDATE_BOX_INFO, {
                id: route.params.id,
                values: box_info.value,
              })
              .then(() => {
                router.push({ name: "box_infos" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
                isSubmit.value = false
              });
          }
        } else {
          isSubmit.value = false
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_BOX_INFO, route.params.id)
        .then(() => {
          box_info.value = store.getters.currentBoxInfo

          imageUrl.value = box_info.value.image_url.startsWith("http")
            ? box_info.value.image_url
            : ""
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const imageUrl = ref("")

    const onChange = (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw)
      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || ""
        box_info.value.image = imageUrl.value
      }
    }

    const box_categories = ref(Array<BoxCategory>())

    store
      .dispatch(Actions.GET_BOX_CATEGORIES)
      .then(() => {
        box_categories.value = store.getters.currentBoxCategories
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

      const event_options = ref([] as any)

      store
        .dispatch(Actions.GET_EVENTS)
        .then(() => {
          event_options.value = [{id: '', name: ''} as any]
          event_options.value = event_options.value.concat(store.getters.currentEvents)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })

    const box_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_TYPES)
      .then(() => {
        box_types.value = store.getters.currentBoxTypes
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const game_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_GAME_TYPES)
      .then(() => {
        game_types.value = store.getters.currentGameTypes
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const double_types = ref([] as any)

    if (!box_info.value.double_type) {
      box_info.value.double_type = 'nonp'
    }

    double_types.value.push({key: '无', value: 'nonp'})
    double_types.value.push({key: '个人双倍', value: 'person'})
    double_types.value.push({key: '全场双倍', value: 'global'})

    // const item_levels = ref(Array<BoxType>())

    const item_levels = (item) => {
      switch (item.item_type) {
        case 'normal':
          return ['SSSR', 'SSR', 'SR', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        case 'range_gift':
          return ['FIRST', 'LAST', 'SP', 'SP1', 'SP2', 'SP3', 'SP4', 'SP5', 'SP6', 'SP7', 'SP8', 'SP9', 'SP10', 'SP11', 'SP12', 'SP13', 'SP14', 'SP15', 'SP16', 'SP17', 'SP18', 'SP19', 'SP20']
        case 'run_gift':
          return ['RUN', 'RUN1', 'RUN2', 'RUN3', 'RUN4', 'RUN5', 'RUN6', 'RUN7', 'RUN8', 'RUN9', 'RUN10', 'RUN11', 'RUN12', 'RUN13', 'RUN14', 'RUN15', 'RUN16', 'RUN17', 'RUN18', 'RUN19', 'RUN20']
        default:
          return []
      }
    }

    // store
    //   .dispatch(Actions.GET_BOX_ITEM_LEVELS)
    //   .then(() => {
    //     item_levels.value = store.getters.currentBoxItemLevels
    //   })
    //   .catch(() => {
    //     // const [error] = Object.keys(store.getters.getErrors);
    //     // Swal.fire({
    //     //   text: store.getters.getErrors[error],
    //     //   icon: "error",
    //     //   buttonsStyling: false,
    //     //   confirmButtonText: "Try again!",
    //     //   customClass: {
    //     //     confirmButton: "btn fw-bold btn-light-danger",
    //     //   },
    //     // });
    //   })

    const box_item_types = ref(Array<BoxType>())
    const level_box_item_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_ITEM_TYPES)
      .then(() => {
        box_item_types.value = store.getters.currentBoxItemTypes
        level_box_item_types.value = store.getters.currentBoxItemTypes.filter((i) => { return i.value === 'normal' })
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const level_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_LEVEL_TYPES)
      .then(() => {
        level_types.value = store.getters.currentBoxLevelTypes
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })
    
    const box_level_item_info_gift_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_LEVEL_ITEM_INFO_GIFT_TYPES)
      .then(() => {
        box_level_item_info_gift_types.value = store.getters.currentBoxLevelItemInfoGiftTypes
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const destroy_item = (item) => {
      item._destroy = 1
    }

    const add_box_item_infos = (products) => {
      products.forEach((i) => {
        if (add_type == 'normal') {
          box_info.value.box_item_infos_attributes.push({
            product_id: i.id,
            brand: i.brand,
            product: i.name,
            price: i.price,
            recycle_price: i.recycle_price,
            item_type: 'normal',
            quantity: 1,
            range_from: 0,
            range_to: 0,
            sorting: box_info.value.box_item_infos_attributes.length,
            is_water: i.is_water,
            combo_count: 0
          } as BoxItemInfo)
        } else if (add_type == 'hidden') {
          box_info.value.hidden_gifts_attributes.push({
            product_id: i.id,
            brand: i.brand,
            product: i.name,
            price: i.price,
            recycle_price: i.recycle_price,
            percent: 0
          } as any)
        } else if (add_type == 'level') {
          currentLevel.box_item_infos_attributes.push({
            product_id: i.id,
            brand: i.brand,
            product: i.name,
            price: i.price,
            recycle_price: i.recycle_price,
            item_type: 'normal',
            quantity: 1,
            range_from: 0,
            range_to: 0,
            sorting: currentLevel.box_item_infos_attributes.length,
            is_water: i.is_water,
            combo_count: 0,
            level_type: 'no_change'
          } as any)
        } else if (add_type == 'levelInfo') {
          currentLevel.product_name = `${i.brand}_${i.ip}_${i.name}`
          currentLevel.product_id = i.id
        }
      })
    }

    const move_up = (item, level?: BoxLevelItemInfo) => {
      if (item.sorting > 0) {
        item.sorting -= 1
      }

      let sorting_items

      if (level !== undefined) {
        sorting_items = _.orderBy(level.box_item_infos_attributes, 'sorting', 'asc').filter((i) => {
          return i != item
        })
      } else {
        sorting_items = _.orderBy(box_info.value.box_item_infos_attributes, 'sorting', 'asc').filter((i) => {
          return i != item
        })
      }

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const move_down = (item, level?: BoxLevelItemInfo) => {
      let sorting_items

      if (level !== undefined) {
        if (item.sorting < level.box_item_infos_attributes.length - 1) {
          item.sorting += 1
        }

        sorting_items = _.orderBy(level.box_item_infos_attributes, 'sorting', 'asc').filter((i) => {
          return i != item
        })
      } else {
        if (item.sorting < box_info.value.box_item_infos_attributes.length - 1) {
          item.sorting += 1
        }

        sorting_items = _.orderBy(box_info.value.box_item_infos_attributes, 'sorting', 'asc').filter((i) => {
          return i != item
        })
      }

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const orderedBoxItemInfos = () => {
      return _.orderBy(box_info.value.box_item_infos_attributes, 'sorting', 'asc')
    }

    const orderedLevelBoxItemInfos = (level) => {
      return _.orderBy(level.box_item_infos_attributes, 'sorting', 'asc')
    }

    const current_role = ref('')

    if (store.getters.currentUser) {
      current_role.value = store.getters.currentUser.role
    }

    const box_info_total = (level: any = null) => {
      if (level) {
        return box_info.value.price * total_count(level)
      } else {
        return box_info.value.price * total_count()
      }
    }

    const item_total = (level: any = null) => {
      let total = 0
      let data = [] as any

      if (level) {
        data = level.box_item_infos_attributes
      } else {
        data = box_info.value.box_item_infos_attributes
      }

      if (box_info.value.game_type == 'infinity') {
        data.filter((i) => {
          return i.item_type == 'normal'
        }).forEach((i) => {
          total += i.recycle_price * i.quantity
        })

        data.filter((i) => {
          return i.item_type == 'range_gift'
        }).forEach((i) => {
          total += i.recycle_price * (total_count() / i.range_to)
        })

        data.filter((i) => {
          return i.item_type == 'run_gift'
        }).forEach((i) => {
          total += i.recycle_price * (total_count() / i.range_to)
        })
      } else {
        data.forEach((i) => {
          total += i.recycle_price * i.quantity
        })
      }

      return total
    }

    const total_profit = (level: any = null) => {
      if (level) {
        return box_info_total(level) - item_total(level)
      } else {
        return box_info_total() - item_total()
      }
    }

    const total_profit_percent = (level: any = null) => {
      if (level) {
        return Math.round((total_profit(level) / box_info_total(level)) * 100) + '%'
      } else {
        return Math.round((total_profit() / box_info_total()) * 100) + '%'
      }
    }

    const total_count = (level: any = null) => {
      let total = 0
      
      if (level) {
        level.box_item_infos_attributes.filter((i) => {
          return i.item_type == 'normal'
        }).forEach((i) => {
          total += i.quantity
        })
      } else {
        box_info.value.box_item_infos_attributes.filter((i) => {
          return i.item_type == 'normal'
        }).forEach((i) => {
          total += i.quantity
        })
      }

      return total
    }

    const levels = ref(Array<Level>())

    store
      .dispatch(Actions.GET_LEVELS)
      .then(() => {
        levels.value = store.getters.currentLevels
        levels.value.unshift({id: null} as any)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const box_products = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_PRODUCTS)
      .then(() => {
        box_products.value = store.getters.currentBoxProducts
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const setAddType = (type, level) => {
      add_type = type
      currentLevel = level
    }

    const addLevel = () => {
      let box_level_item_info = {} as BoxLevelItemInfo
      box_level_item_info.level = getLastLevel() + 1
      box_level_item_info.box_item_infos_attributes = []
      box_info.value.box_level_item_infos_attributes.push(box_level_item_info)
    }

    const getLastLevel = () => {
      return box_info.value.box_level_item_infos_attributes.filter((i) => { return !i._destroy }).length
    }

    const destroyLevel = (level) => {
      level._destroy = 1
      console.log(level)

      let index = 1
      _.orderBy(box_info.value.box_level_item_infos_attributes, 'level', 'asc').filter((i) => { return !i._destroy }).forEach((i) => {
        console.log(i)
        i.level = index
        index++
      })
    }

    const sortByPrice = (level) => {
      let index = 0

      _.orderBy(box_info.value.box_item_infos_attributes, 'recycle_price', 'desc').forEach((i) => {
        i['sorting'] = index
        index++
      })
    }

    return {
      onSubmit,
      box_info,
      rules,
      onCancel,
      formRef,
      box_categories,
      imageUrl,
      onChange,
      box_types,
      game_types,
      item_levels,
      box_item_types,
      level_box_item_types,
      destroy_item,
      add_box_item_infos,
      move_up,
      move_down,
      orderedBoxItemInfos,
      orderedLevelBoxItemInfos,
      current_role,
      total_profit,
      total_profit_percent,
      total_count,
      box_info_total,
      item_total,
      box_products,
      setAddType,
      levels,
      event_options,
      double_types,
      addLevel,
      destroyLevel,
      level_types,
      box_level_item_info_gift_types,
      sortByPrice
    }
  }
})
